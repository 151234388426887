import React from "react";
import Icon from "./Icon";
import { ButtonProps } from "../../types/ComponentsTypes";


const Button: React.FC<ButtonProps> = ({
  label,
  textClassName = "text-sm text-black",
  className = "bg-gray-200",
  iconName,
  iconClassName,
  onClick,
  onMouseEnter,
  onMouseLeave,
  type = "button",
  disabled
}) => {
  return (
    <button
      type={type}
      className={`flex justify-center items-center gap-2 min-w-40 px-2 h-10 rounded-xl text-white shadow-lg ${className}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
    >
      {iconName && <Icon name={iconName} size={20} className={iconClassName} />}
      <span className={textClassName}>{label}</span>
    </button>
  );
};

export default Button;
