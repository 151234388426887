import Modals from "./components/Modals/Modals";
import { AuthProvider } from "./contexts/AuthContext";
import SlideProvider from "./contexts/SlideContext";
import { UIProvider } from "./contexts/UIContext";
import SlideCraft from "./SlideCraft";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WorkspaceProvider } from "./contexts/WorkspaceContext";

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <WorkspaceProvider>
          <SlideProvider>
            <UIProvider>
              <SlideCraft />
              <Modals />
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </UIProvider>
          </SlideProvider>
        </WorkspaceProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
