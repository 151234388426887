import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSlideContext } from "../contexts/SlideContext";
import { getProjectById } from "../api/project";
import SlideLayout from "../components/SlideCanvas/SlideLayout";
import Icon from "../components/common/Icon";

const ProjectPreview = () => {
  const { projectUuid } = useParams();
  const navigate = useNavigate();
  const {
    projectData,
    setProjectData,
    slides,
    setSlides,
    selectedSlide,
    setSelectedSlide,
    canvasState,
    toggleScreenType,
  } = useSlideContext();

  useEffect(() => {
    const fetchProjectData = async () => {
      if (!projectUuid) return;

      try {
        const project = await getProjectById(projectUuid);

        setProjectData(project);

        if (project.data?.slides) {
          setSlides(project.data.slides);
          setSelectedSlide(project.data.slides[0] || null); // İlk slaytı seç
        }
      } catch (error) {
        console.error("Proje verisi alınamadı:", error);
      }
    };

    fetchProjectData();
  }, [projectUuid]);

  

  const goToPreviousSlide = () => {
    if (!selectedSlide || slides.length === 0) return;

    const currentIndex = slides.findIndex(
      (slide) => slide.id === selectedSlide.id
    );

    if (currentIndex > 0) {
      setSelectedSlide(slides[currentIndex - 1]);
    }
  };

  const goToNextSlide = () => {
    if (!selectedSlide || slides.length === 0) return;

    const currentIndex = slides.findIndex(
      (slide) => slide.id === selectedSlide.id
    );

    if (currentIndex < slides.length - 1) {
      setSelectedSlide(slides[currentIndex + 1]);
    }
  };

  const restartPresentation = () => {
    if (slides.length > 0) {
      setSelectedSlide(slides[0]); // İlk slayta dön
    }
  };

  const navigateToEdit = () => {
    console.log("giriyor mu");

    if (projectData) {
      navigate(
        `/projects/${projectUuid}/edit`
      );
    }
  };

  if (!projectData || !slides || slides.length === 0) {
    return <div>Proje verisi yükleniyor...</div>;
  }

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-gray-100">
      <Header
        toggleScreenType={toggleScreenType}
        restartPresentation={restartPresentation}
        navigateToEdit={navigateToEdit}
      />
      <div
        className={`relative ${
          canvasState.screenType === "mobile" ? "w-[420px]" : "w-[1024px]"
        } h-[600px] border bg-white border-gray-200`}
      >
        <SlideLayout readonly={true} />
        <div className="absolute bottom-3 right-8">
          <div className="flex items-center gap-2">
            <button
              onClick={goToPreviousSlide}
              className="px-3 py-1 bg-[#2a61bb]  hover:bg-[#3c73cc]"
            >
              <Icon name="arrow-up-icon" size={24} className="text-white" />
            </button>
            <button
              onClick={goToNextSlide}
              className="px-3 py-1 bg-[#2a61bb] hover:bg-[#3c73cc]"
            >
              <Icon name="arrow-down-icon" size={24} className="text-white"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Header = ({
  toggleScreenType,
  restartPresentation,
  navigateToEdit,
}: {
  toggleScreenType: () => void;
  restartPresentation: () => void;
  navigateToEdit: () => void;
}) => {
  const { canvasState } = useSlideContext();

  return (
    <header className="flex items-center justify-between w-[130px] h-[40px] p-4 bg-[#f7f7f6] shadow-md mb-5">
      <div
        className="flex items-center cursor-pointer"
        onClick={toggleScreenType}
      >
        <Icon name={`${canvasState.screenType}-icon`} size={24} />
      </div>
      <button onClick={restartPresentation} className="flex items-center">
        <Icon name="refresh-icon" size={20} />
      </button>
      <button onClick={navigateToEdit} className="flex items-center">
        <Icon name="close-icon" size={20} />
      </button>
    </header>
  );
};

export default ProjectPreview;
