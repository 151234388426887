import React, { useEffect } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { getMe, logout } from "../../api/auth";
import { toast } from "react-toastify";

const UserDisplay = () => {
  const { username, setUsername,setIsLogin } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      toast.success("Başarıyla çıkış yapıldı.");
      setIsLogin(false)
      setTimeout(() => navigate("/auth"), 500); 
    } catch (error) {
      console.error("Çıkış işlemi başarısız:", error);
      toast.error("Çıkış işlemi sırasında bir hata oluştu.");
    }
  };

  const fetchUser = async () => {
    try {
      const user = await getMe(); // API'den kullanıcı bilgisi al
      setUsername(user.username);
    } catch (error) {
      console.error("Kullanıcı bilgileri alınamadı:", error);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate("/auth");
    }
  };

  useEffect(() => {
    fetchUser();
  },[]);

  return (
    <span
      className="text-md text-black underline underline-offset-4 cursor-pointer"
      onClick={username ? handleLogout : () => navigate("/auth")}
    >
      {username ? username : "Giriş Yap"}
    </span>
  );
};

export default UserDisplay;
