import React, { useState } from "react";
import AuthInput from "./AuthInput";
import Button from "../common/Button";
import { useAuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { login, register } from "../../api/auth";
import { toast } from "react-toastify";

interface AuthFormLayoutProps {
  toggleFormType: () => void;
  onSubmit: () => void;
}

const AuthForm = () => {
  const { authFormState, toggleFormType,setIsLogin } = useAuthContext();
  const navigate = useNavigate();

  const validateForm = (): boolean => {
    if (authFormState.formType === "loginForm") {
      const username = authFormState.loginForm.username.trim();
      const password = authFormState.loginForm.password.trim();
      if (!username || !password) {
        toast.error("Kullanıcı adı ve şifre boş bırakılamaz.");
        return false;
      }
    } else {
      const username = authFormState.registerForm.username.trim();
      const email = authFormState.registerForm.email.trim();
      const password = authFormState.registerForm.password.trim();
      const passwordRepeat = authFormState.registerForm.passwordRepeat.trim();

      if (!username || !email || !password || !passwordRepeat) {
        toast.error("Tüm alanları doldurmanız gerekiyor.");
        return false;
      }
      if (password !== passwordRepeat) {
        toast.error("Şifreler eşleşmiyor.");
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      if (authFormState.formType === "loginForm") {
        const data = await login(
          authFormState.loginForm.username,
          authFormState.loginForm.password
        );

        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        toast.success("Giriş başarılı! Ana sayfaya yönlendiriliyorsunuz.");
        setIsLogin(true)
        setTimeout(() => {
          navigate("/"); // Ana sayfaya yönlendirme
        }, 1500);
      } else {
        await register(
          authFormState.registerForm.username,
          authFormState.registerForm.email,
          authFormState.registerForm.password
        );
        toast.success("Kayıt başarılı! Şimdi giriş yapabilirsiniz.");
        toggleFormType(); // Login formuna geçiş
      }
    } catch (error: any) {
      toast.error(error.message || "Bir hata oluştu.");
    }
  };

  return (
    <div>
      {authFormState.formType === "loginForm" ? (
        <LoginLayout toggleFormType={toggleFormType} onSubmit={handleSubmit} />
      ) : (
        <RegisterLayout
          toggleFormType={toggleFormType}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

const LoginLayout: React.FC<AuthFormLayoutProps> = ({
  toggleFormType,
  onSubmit,
}) => (
  <div className="w-96 h-auto py-6 px-6 shadow-xl rounded-xl bg-[#f7f7f7]">
    <header>
      <h3 className="text-2xl font-bold text-center text-gray-700 tracking-widest mb-4">
        GİRİŞ YAP
      </h3>
    </header>
    <div className="flex flex-col gap-4">
      <AuthInput
        name="username"
        type="text"
        iconName="person-icon"
        placeholder="Kullanıcı adı"
      />
      <AuthInput
        name="password"
        type="password"
        iconName="password-icon"
        placeholder="Şifre"
      />
    </div>
    <Button
      label="Giriş Yap"
      className="w-full mt-4 bg-[#FF7777] h-10 shadow-sm"
      textClassName="text-xl"
      onClick={onSubmit} // Login işlemini tetikle
    />
    <p className="text-sm text-gray-500 text-center mt-3">
      Henüz üye olmadınız mı?
      <span
        className="underline cursor-pointer ml-1 text-gray-700"
        onClick={toggleFormType}
      >
        Kayıt ol
      </span>
    </p>
  </div>
);

const RegisterLayout: React.FC<AuthFormLayoutProps> = ({
  toggleFormType,
  onSubmit,
}) => (
  <div className="w-96 h-auto py-6 px-6 shadow-xl rounded-xl bg-[#f7f7f7]">
    <header>
      <h3 className="text-2xl font-bold text-center text-gray-700 tracking-widest mb-4">
        KAYIT OL
      </h3>
    </header>
    <div className="flex flex-col gap-4">
      <AuthInput
        name="username"
        type="text"
        iconName="person-icon"
        placeholder="Kullanıcı adı"
      />
      <AuthInput
        name="email"
        type="email"
        iconName="email-icon"
        placeholder="Email"
      />
      <AuthInput
        name="password"
        type="password"
        iconName="password-icon"
        placeholder="Şifre"
      />
      <AuthInput
        name="passwordRepeat"
        type="password"
        iconName="password-icon"
        placeholder="Şifre tekrar"
      />
    </div>
    <Button
      label="Kayıt Ol"
      className="w-full mt-4 bg-[#FF7777] h-10 shadow-sm"
      textClassName="text-xl"
      onClick={onSubmit} // Login işlemini tetikle
    />
    <p className="text-sm text-gray-500 text-center mt-3">
      Hesabınız var mı?
      <span
        className="underline cursor-pointer ml-1 text-gray-700"
        onClick={toggleFormType}
      >
        Giriş yap
      </span>
    </p>
  </div>
);

export default AuthForm;
