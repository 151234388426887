import axios from "axios";

// Axios'un varsayılan ayarlarını yapılandır
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// getMyWorkspacesWithProjects fonksiyonu
export const getMyWorkspacesWithProjects = async (): Promise<any> => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get("/workspaces", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error("Axios hatası:", error.message);
      throw new Error(
        error.response?.data?.message ||
          "Çalışma alanları alınırken bir hata oluştu."
      );
    } else {
      console.error("Beklenmedik bir hata:", error);
      throw new Error("Bilinmeyen bir hata oluştu.");
    }
  }
};

// createWorkspace fonksiyonu
export const createWorkspace = async (
  name: string,
  members: { username: string; roleId: number }[]
) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      "/workspaces",
      { name, members },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error("Axios hatası:", error.message);
      throw new Error(
        error.response?.data?.message ||
          "Çalışma alanı oluşturulurken bir hata oluştu."
      );
    } else {
      console.error("Beklenmedik bir hata:", error);
      throw new Error("Bilinmeyen bir hata oluştu.");
    }
  }
};

// checkUsername fonksiyonu
export const checkUsername = async (username: string): Promise<boolean> => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      "/workspaces/check-username",
      { username },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.exists; // API `true` veya `false` döndürüyor.
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error("Axios hatası:", error.message);
      throw new Error(
        error.response?.data?.message ||
          "Kullanıcı adı kontrol edilirken bir hata oluştu."
      );
    } else {
      console.error("Beklenmedik bir hata:", error);
      throw new Error("Bilinmeyen bir hata oluştu.");
    }
  }
};

// updateWorkspace fonksiyonu
export const updateWorkspace = async (
  workspaceId: number,
  name: string
): Promise<void> => {
  try {
    const token = localStorage.getItem("accessToken");
    await axios.put(
      `/workspaces/${workspaceId}`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error("Axios hatası:", error.response?.data);
      throw new Error(
        error.response?.data?.message ||
          "Workspace güncellenirken bir hata oluştu."
      );
    } else {
      console.error("Beklenmedik bir hata:", error);
      throw new Error("Bilinmeyen bir hata oluştu.");
    }
  }
};

// Workspace kullanıcılarını getirme
export const getWorkspaceUsers = async (
  workspaceId: number
): Promise<any[]> => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.get(`/workspaces/${workspaceId}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Kullanıcı listesi döndürülüyor
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error("Axios hatası:", error.message);
      throw new Error(
        error.response?.data?.message ||
          "Çalışma alanı kullanıcıları alınırken bir hata oluştu."
      );
    } else {
      console.error("Beklenmedik bir hata:", error);
      throw new Error("Bilinmeyen bir hata oluştu.");
    }
  }
};

// Workspace silme
export const deleteWorkspace = async (workspaceId: number): Promise<void> => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.delete(`/workspaces/${workspaceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200 || response.status === 204) {
      console.log("Çalışma alanı başarıyla silindi.");
    } else {
      throw new Error("Çalışma alanı silinirken beklenmedik bir durum oluştu.");
    }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error("Axios hatası:", error.message);
      throw new Error(
        error.response?.data?.message ||
          "Çalışma alanı silinirken bir hata oluştu."
      );
    } else {
      console.error("Beklenmedik bir hata:", error);
      throw new Error("Bilinmeyen bir hata oluştu.");
    }
  }
};

// Workspace'ten kullanıcı çıkarma
export const removeWorkspaceUser = async (
  workspaceId: number,
  username: string
): Promise<void> => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.delete(
      `/workspaces/${workspaceId}/users/${username}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200 || response.status === 204) {
      console.log("Üye başarıyla çıkarıldı.");
    } else {
      throw new Error("Beklenmedik bir durum oluştu.");
    }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error("Axios hatası:", error.message);
      throw new Error(
        error.response?.data?.message ||
          "Üye çıkarılırken bir hata oluştu."
      );
    } else {
      console.error("Beklenmedik bir hata:", error);
      throw new Error("Bilinmeyen bir hata oluştu.");
    }
  }
};
