import Icon from "../common/Icon";
import { useSlideContext } from "../../contexts/SlideContext";
import SlideLayout from "./SlideLayout";

const SlideCanvas = () => {
  const {
    canvasState,
    slides,
    projectData,
    setProjectData,
    setSelectedSlide,
    projectPermissions,
  } = useSlideContext();

  // Eğer izinler arasında "edit" yoksa readonly true olur
  const isReadonly = !projectPermissions.includes("edit");

  return (
    <div className="flex-1 flex flex-col justify-center items-center gap-4 relative z-50">
      <Header />

      <div
        className={`${
          canvasState.screenType === "mobile" ? "w-[420px]" : "w-full"
        } h-full border bg-white border-gray-200`}
      >
        <SlideLayout readonly={isReadonly} />
      </div>
    </div>
  );
};

export default SlideCanvas;


const Header = () => {
  const { canvasState, toggleScreenType, projectData } = useSlideContext();

  const handlePreview = () => {
    if (!projectData) return;

    window.open(
      `${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData.projectUuid}/preview`,
      "_blank"
    );
  };

  return (
    <header className="flex items-center w-full h-12 px-6 bg-[#f7f7f6] rounded-xl z-30 relative">
      <div className="flex items-center gap-2 cursor-pointer">
        <Icon name={`${canvasState.contentMode}-mode-icon`} size={20} />
        <span className="font-extralight text-sm capitalize">
          {canvasState.contentMode === "survey" && "form modu"}
          {canvasState.contentMode === "presentation" && "sunum modu"}
        </span>
      </div>
      <div className="h-full w-0.5 bg-[#ededec] mx-4"></div>

      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={toggleScreenType}
      >
        <Icon name={`${canvasState.screenType}-icon`} size={24} />
        <span className="font-extralight text-sm capitalize">
          {canvasState.screenType === "desktop" && "masaüstü görünümü"}
          {canvasState.screenType === "mobile" && "Mobile görünümü"}
        </span>
      </div>
      <div className="h-full w-0.5 bg-[#ededec] mx-4"></div>

      <div
        className="flex items-center cursor-pointer"
        onClick={handlePreview} // Önizleme işlevini bağlıyoruz
      >
        <Icon name="preview-icon" size={24} />
        <span className="font-extralight text-sm cursor-pointer">
          Ön İzleme
        </span>
      </div>
    </header>
  );
};
