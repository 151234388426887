import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSlideContext } from "../contexts/SlideContext";
import { getProjectById, getPublicProjectById } from "../api/project";
import SlideLayout from "../components/SlideCanvas/SlideLayout";
import { useMediaQuery } from "react-responsive";
import { useAuthContext } from "../contexts/AuthContext";
import Icon from "../components/common/Icon";

const ProjectPublish = () => {
  const { projectUuid } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const {
    projectData,
    setProjectData,
    slides,
    setSlides,
    selectedSlide,
    setSelectedSlide,canvasState,setCanvasState
  } = useSlideContext();

  useEffect(() => {
    const fetchProjectData = async () => {
      if (!projectUuid) return;
  
      try {
        const token = localStorage.getItem("accessToken"); // Token kontrolü
  
        let project;
  
        if (token) {
          // Token varsa kullanıcı giriş yapmış, private API'yi kullan
          project = await getProjectById(projectUuid);
        } else {
          // Token yoksa public API'yi kullan
          project = await getPublicProjectById(projectUuid);
        }
  
        setProjectData(project);
  
        if (project.data?.slides) {
          setSlides(project.data.slides);
          setSelectedSlide(project.data.slides[0] || null); // İlk slaytı seç
        }
      } catch (error) {
        console.error("Proje verisi alınamadı:", error);
      }
    };
  
    fetchProjectData();
  }, [projectUuid]);
  

  useEffect(() => {
    // Mobilde ise screenType'ı "mobile", değilse "desktop" yap
    if (isMobile) {
      setCanvasState((prev) => ({...prev,screenType:'mobile'}))
    } else {
      setCanvasState((prev) => ({...prev,screenType:'desktop'}))

    }
  }, [isMobile, canvasState.screenType]);

  if (!projectData || !slides || slides.length === 0) {
    return <div>Proje yayında değil veya yükleniyor...</div>;
  }

  const currentSlideIndex = slides.findIndex(
    (slide) => slide.id === selectedSlide?.id
  );

  const isFirstSlide = currentSlideIndex === 0;
  const isLastSlide = currentSlideIndex === slides.length - 1;

  const goToPreviousSlide = () => {
    if (isFirstSlide) return;
    setSelectedSlide(slides[currentSlideIndex - 1]);
  };

  const goToNextSlide = () => {
    if (isLastSlide) return;
    setSelectedSlide(slides[currentSlideIndex + 1]);
  };

  return (
    <div
      className={`w-screen h-screen flex flex-col items-center justify-center bg-gray-100`}
    >
      <div
        className={`relative ${
          isMobile ? "w-full h-full" : "w-[1024px]"
        } h-[600px] border bg-white border-gray-200`}
      >
        <SlideLayout readonly={true} />

        <div className="absolute bottom-3 right-8">
          <div className="flex items-center gap-2">
            <button
              onClick={goToPreviousSlide}
              className="px-3 py-1 bg-[#2a61bb]  hover:bg-[#3c73cc]"
            >
              <Icon name="arrow-up-icon" size={24} className="text-white" />
            </button>
            <button
              onClick={goToNextSlide}
              className="px-3 py-1 bg-[#2a61bb] hover:bg-[#3c73cc]"
            >
              <Icon name="arrow-down-icon" size={24} className="text-white"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPublish;
