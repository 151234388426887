import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import Icon from "../common/Icon";
import { useWorkspaceContext } from "../../contexts/WorkspaceContext"; // Workspace context
import { useUIContext } from "../../contexts/UIContext";
import { Workspace } from "../../types/ContextTypes";

interface WorkspaceItemProps {
  workspaceItems: Workspace[];
}

const HomeWorkspacesBar = () => {
  const { ownedWorkspaces, memberWorkspaces } = useWorkspaceContext(); // Context kullanımı
  const { showModal } = useUIContext(); // Modal state alındı


  return (
    <div className="basis-[13%] h-full p-6 bg-[#f7f7f6] rounded-xl">
      {/* Yeni Proje Ekle Butonu */}
      <div className="flex justify-center mb-6">
        <Button
          label="Yeni Proje"
          iconName="add-icon"
          className="w-5/6 h-9 bg-black"
          textClassName="text-md text-white"
          onClick={() => showModal("isAddProjectModalVisible")}
        />
      </div>

      {/* Çalışma Alanları Başlık */}
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Icon name="workspace-icon" className="fill-black" size={18} />
          <span className="text-sm text-black">Çalışma Alanları</span>
        </div>
        <div
          className="w-7 h-7 flex justify-center items-center border border-gray-300 rounded-lg bg-white cursor-pointer"
          onClick={() => showModal("isAddWorkspaceModalVisible")}
        >
          <Icon name="add-icon" className="fill-black" size={18} />
        </div>
      </div>

      <div className="w-full h-0.5 border border-gray-200 my-4"></div>

      {/* Owned Workspaces (Sahip Olunan Çalışma Alanları) */}
      <div>
        <h3 className="text-sm font-bold text-gray-700 mb-2">
          Sahip Olduklarım
        </h3>

        <WorkspaceItem workspaceItems={ownedWorkspaces} />
      </div>
      <div className="w-full h-0.5 border border-gray-200 my-4"></div>

      {/* Member Workspaces (Üye Olunan Çalışma Alanları) */}
      <div>
        <h3 className="text-sm font-bold text-gray-700 mb-2">Katıldıklarım</h3>
        <WorkspaceItem workspaceItems={memberWorkspaces} />
      </div>
    </div>
  );
};

export default HomeWorkspacesBar;

const WorkspaceItem: React.FC<WorkspaceItemProps> = ({ workspaceItems }) => {
  const { selectedWorkspace, setSelectedWorkspace } = useWorkspaceContext(); // Context kullanımı


  return (
    <div className="flex flex-col gap-2">
      {workspaceItems.map((workspace) => (
        <div
          key={workspace.workspaceId}
          onClick={() => setSelectedWorkspace(workspace)}
          className={`flex justify-between items-center h-8 px-2 cursor-pointer rounded-lg ${
            workspace.workspaceId !== selectedWorkspace?.workspaceId
              ? "hover:bg-[#efefee]"
              : "bg-[#e7e7e7]"
          }`}
        >
          <span className="text-sm text-[#5e5e5e]">
            {workspace.workspaceName.length > 16
              ? `${workspace.workspaceName.substring(0, 16)}...`
              : workspace.workspaceName}
          </span>
          <span className="text-sm text-gray-500">
            {workspace.projectCount || 0}
          </span>
        </div>
      ))}
    </div>
  );
};
