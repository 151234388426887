import React, { useEffect } from "react";
import HomeWorkspacesBar from "../components/HomeWorkspacesBar/HomeWorkspacesBar";
import HomeWorkspacesContent from "../components/HomeWorkspacesContent/HomeWorkspacesContent";
import UserDisplay from "../components/UserDisplay/UserDisplay";
import { getMyWorkspacesWithProjects, getWorkspaceUsers } from "../api/workspace";
import { useWorkspaceContext } from "../contexts/WorkspaceContext";
import { useAuthContext } from "../contexts/AuthContext";

const Home = () => {
  const {
    setWorkspaceMyRole,
    selectedWorkspace,
    setSelectedWorkspace,
    ownedWorkspaces,
    memberWorkspaces,
    setMemberWorkspaces,
    setOwnedWorkspaces,
    setWorkspaceUsers
  } = useWorkspaceContext();
  const { username } = useAuthContext();


  useEffect(() => {
    
    const fetchWorkspaces = async () => {
      try {
        const workspacesWithProjects = await getMyWorkspacesWithProjects();
  
        // ownedWorkspaces ve memberWorkspaces verilerini ayrıştır
        const formattedOwnedWorkspaces = workspacesWithProjects.ownedWorkspaces.map((workspace:any) => ({
          workspaceId: workspace.workspaceId,
          workspaceName: workspace.workspaceName,
          projectCount: workspace.projectCount,
          workspaceProjects: workspace.projects,
        }));
  
        const formattedMemberWorkspaces = workspacesWithProjects.memberWorkspaces.map((workspace:any) => ({
          workspaceId: workspace.workspaceId,
          workspaceName: workspace.workspaceName,
          projectCount: workspace.projectCount,
          workspaceProjects: workspace.projects,
        }));
  
        setOwnedWorkspaces(formattedOwnedWorkspaces); // Sahip olunan workspaces
        setMemberWorkspaces(formattedMemberWorkspaces); // Üye olunan workspaces
      } catch (error) {
        console.error("Failed to fetch workspaces:", error);
      }
    };
  
    fetchWorkspaces();
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const fetchWorkspaceDetails = async () => {
        try {
          // Çalışma alanı üyelerini API'den çek
          const workspaceMembers = await getWorkspaceUsers(selectedWorkspace.workspaceId);
          setWorkspaceUsers(workspaceMembers);

          // Kullanıcının rolünü belirle
          const currentUser = workspaceMembers.find((member: any) => member.username === username);
          if (currentUser) {
            setWorkspaceMyRole(currentUser.role); // "owner", "admin", "member"
          } else {
            setWorkspaceMyRole(null); // Kullanıcı çalışma alanına dahil değil
          }
        } catch (error) {
          console.error("Failed to fetch workspace details:", error);
        }
      };

      fetchWorkspaceDetails();
    } else {
      // Eğer seçili bir çalışma alanı yoksa, rolü ve üyeleri sıfırla
      setWorkspaceMyRole(null);
      setWorkspaceUsers([]);
    }
  }, [selectedWorkspace, username]);

  useEffect(() => {
    if (!selectedWorkspace) return;
  
    const updatedWorkspace =
      ownedWorkspaces.find((ws) => ws.workspaceId === selectedWorkspace.workspaceId) ||
      memberWorkspaces.find((ws) => ws.workspaceId === selectedWorkspace.workspaceId);
  
    if (updatedWorkspace) {
      setSelectedWorkspace(updatedWorkspace);
    }
  }, [ownedWorkspaces, memberWorkspaces, selectedWorkspace, setSelectedWorkspace]);
  
  return (
    <div className="w-screen h-screen p-6 flex flex-col">
      <header className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold text-[#FF7777]">Flex Slider</h1>
        <UserDisplay />
      </header>
      <div className="flex gap-3 w-full h-full">
        <HomeWorkspacesBar />
        <HomeWorkspacesContent />
      </div>
    </div>
  );
};

export default Home;
