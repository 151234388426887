import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Auth from "./pages/Auth";
import SlideEdit from "./pages/ProjectEdit";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./routes/PrivateRoute";
import ProjectPublish from "./pages/ProjectPublish";
import ProjectPreview from "./pages/ProjectPreview";

const SlideCraft = () => {
  return (
    <div className="font-roboto select-none">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth" element={<PrivateRoute><Auth/></PrivateRoute>} />
        <Route path="/projects/:projectUuid/edit" element={<SlideEdit />} />
        <Route path="/projects/:projectUuid/publish" element={<ProjectPublish />} />
        <Route path="/projects/:projectUuid/preview" element={<ProjectPreview />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default SlideCraft;
