import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { AuthContextType, AuthFormState } from "../types/ContextTypes";
import { getMe } from "../api/auth"; // Kullanıcı bilgisi almak için gerekli fonksiyon

const defaultFormState: AuthFormState = {
  formType: "loginForm",
  loginForm: {
    username: "",
    password: "",
  },
  registerForm: {
    username: "",
    email: "",
    password: "",
    passwordRepeat: "",
  },
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [authFormState, setAuthFormState] =
    useState<AuthFormState>(defaultFormState);
  const [username, setUsername] = useState<string | null>(null);
  const [isLogin,setIsLogin] = useState<boolean>(false);
  

  const toggleFormType = () => {
    setAuthFormState((prevState) => ({
      ...prevState,
      formType: prevState.formType === "loginForm" ? "registerForm" : "loginForm",
    }));
  };

  return (
    <AuthContext.Provider
      value={{ authFormState, setAuthFormState, toggleFormType, username ,setUsername,isLogin,setIsLogin}}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
