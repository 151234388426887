// src/api/auth.ts
const API_URL = `${process.env.REACT_APP_API_URL}/auth`;

export const login = async (username: string, password: string) => {
  
  const response = await fetch(`${API_URL}/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  });
  
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Login failed");
  }
  return data;
};

export const register = async (username: string, email: string, password: string) => {
  const response = await fetch(`${API_URL}/register`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, email, password }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Registration failed");
  }
  return data;
};

export const logout = async () => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    throw new Error("Token not found");
  }

  const response = await fetch(`${API_URL}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Tokeni gönderiyoruz
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Logout failed");
  }

  return response.json();
};

export const getMe = async (): Promise<{ id: number; username: string }> => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    throw new Error("Token bulunamadı.");
  }

  const response = await fetch(`${API_URL}/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Tokeni gönderiyoruz
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || "Kullanıcı bilgileri alınamadı.");
  }

  return response.json();
};
