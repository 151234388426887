import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import {
  CanvasState,
  SlideContextType,
  Slide,
  ProjectData,
  Permission,
} from "../types/ContextTypes";

const SlideContext = createContext<SlideContextType | null>(null);

function SlideProvider({ children }: { children: ReactNode }) {
  const [projectData, setProjectData] = useState<ProjectData | null>(null);
  const [projectPermissions, setProjectPermissions] = useState<Permission[]>(
    []
  );

  const [canvasState, setCanvasState] = useState<CanvasState>({
    screenType: "desktop",
    contentMode: "presentation",
  });

  const [slides, setSlides] = useState<Slide[]>([]);
  const [selectedSlide, setSelectedSlide] = useState<Slide | null>(null);

  // Fonksiyonlar

  const toggleScreenType = () => {
    setCanvasState((prev) =>
      prev.screenType === "desktop"
        ? { ...prev, screenType: "mobile" }
        : { ...prev, screenType: "desktop" }
    );
  };

  return (
    <SlideContext.Provider
      value={{
        projectData,
        setProjectData,
        canvasState,
        setCanvasState,
        slides,
        setSlides,
        selectedSlide,
        setSelectedSlide,
        toggleScreenType,
        projectPermissions,
        setProjectPermissions,
      }}
    >
      {children}
    </SlideContext.Provider>
  );
}

export const useSlideContext = (): SlideContextType => {
  const context = useContext(SlideContext);
  if (!context) {
    throw new Error("SlideContext must be used within a SlideProvider");
  }
  return context;
};

export default SlideProvider;
