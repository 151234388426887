import React from "react";
import { Reorder } from "framer-motion";
import SlideItem from "./SlideItem";
import Button from "../common/Button";
import { useUIContext } from "../../contexts/UIContext";
import { useSlideContext } from "../../contexts/SlideContext";
import { ProjectData, Slide } from "../../types/ContextTypes";

const SlidesBar = () => {
  const { slides, setSlides, projectData, setProjectData, projectPermissions } =
    useSlideContext();

  const handleReorder = (newOrder: Slide[]) => {
    const reorderedSlides = newOrder.map((slide, index) => ({
      ...slide,
      order: index + 1, // Yeni sıralamaya göre order ataması
    }));

    const updatedProjectData: ProjectData = {
      ...projectData!,
      data: {
        ...projectData!.data,
        slides: reorderedSlides, // slides'ı data altında güncelliyoruz
      },
    };

    setSlides(reorderedSlides);
    setProjectData(updatedProjectData);
  };

  const addNewSlide = () => {
    const maxId =
      slides.length > 0 ? Math.max(...slides.map((slide) => slide.id)) : 0;

    const newSlide: Slide = {
      id: maxId + 1,
      order: slides.length + 1,
      assetLink: "",
      code: {
        content: "",
        language: "",
      },
      settings: {
        design: {
          titleSize: "medium",
          titleColor: "#333333",
          descSize: "small",
          descColor: "#666666",
        },
        layout: {
          mobile: "layout1",
          desktop: "layout1",
        },
      },
      template: {
        type: "text",
        iconName: "text-icon",
        iconTitle: "Metin",
        templateContent: {
          title: "",
          desc: "",
        },
      },
    };

    const updatedSlides = [...slides, newSlide];

    const updatedProjectData: ProjectData = {
      ...projectData!,
      data: {
        slides: updatedSlides,
      },
    };

    setSlides(updatedSlides);
    setProjectData(updatedProjectData);
  };

  return (
    <aside className="relative basis-[13%] h-full p-4 bg-[#f7f7f6] rounded-xl shadow-md">
      <Reorder.Group
        axis="y"
        values={slides}
        onReorder={handleReorder}
        className="flex flex-col gap-2"
      >
        {slides.map((slide) => (
          <Reorder.Item key={slide.id} value={slide}>
            <SlideItem
              order={slide.order}
              title={slide.template.iconTitle}
              iconName={slide.template.iconName}
              slideId={slide.id}
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>

      <div className="absolute bottom-6 left-1/2 -translate-x-1/2">
        {projectPermissions.includes("edit") && (
          <Button
            iconName="add-icon"
            iconClassName="text-black"
            label="Slide Ekle"
            className="bg-white"
            onClick={addNewSlide}
          />
        )}
      </div>
    </aside>
  );
};

export default SlidesBar;
