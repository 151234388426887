import React from "react";
import { useSlideContext } from "../../contexts/SlideContext";
import { useUIContext } from "../../contexts/UIContext";
import Icon from "../common/Icon";
import { toast } from "react-toastify";

const ShareProjectModal = () => {
  const { projectData } = useSlideContext();
  const { hideAllModals } = useUIContext();

  const handleCopyToClipboard = () => {
    const shareLink = `${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData?.projectUuid}/edit`;
    navigator.clipboard.writeText(shareLink);
    toast.info('Link kopyalandı')
  };

  return (
<div className="relative w-96 bg-white rounded-xl p-6">
  {/* Başlık ve Kapatma İkonu */}
  <div className="flex justify-between items-center mb-4">
    <h2 className="text-lg font-medium text-gray-800">Projeyi Paylaş</h2>
    <div onClick={hideAllModals}>
      <Icon
        name="close-icon"
        className="fill-gray-400 cursor-pointer"
        size={24}
      />
    </div>
  </div>

  {/* Açıklama ve Link */}
  <div className="mb-4">
    <p className="text-sm text-gray-600">
      Bu bağlantıyı kullanarak projeyi düzenleyebilirsiniz.
    </p>
    <div className="mt-3 flex items-center gap-3 p-2 border border-gray-300 rounded-lg">
      <span className="text-sm text-gray-700 flex-grow truncate">
        {`${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData?.projectUuid}/edit`}
      </span>
      <div
        className="text-blue-500 hover:text-blue-700 text-sm cursor-pointer"
        onClick={handleCopyToClipboard}
      >
        <Icon name="copy-icon" size={20} />
      </div>
    </div>

    {/* Bilgilendirme Mesajı */}
    <div className="mt-4 flex items-center gap-2 text-gray-600">
      <Icon name="info-icon" size={20} />
      <p className="text-sm">
        Sadece aynı çalışma alanındaki kişiler erişebilir.
      </p>
    </div>
  </div>
</div>

  );
};

export default ShareProjectModal;
