import React from "react";
import { ModalContainerProps } from "../../types/ComponentsTypes";
import { useUIContext } from "../../contexts/UIContext";

const ModalContainer: React.FC<ModalContainerProps> = ({ children }) => {
  const { modalsState, hideAllModals } = useUIContext();

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {modalsState.isBlackoutVisible && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={hideAllModals}
        ></div>
      )}
      <div className="relative z-50">{children}</div>
    </div>
  );
};

export default ModalContainer;
