import AuthForm from "../components/AuthForm/AuthForm";

const Auth = () => {
  return (
    <div className="relative w-screen h-screen flex justify-center items-center bg-gradient-to-br from-[#FAFAFA] to-[#FCFAEE]">
      <header className="absolute left-1/2 top-6 -translate-x-1/2 ">
        <h1 className="text-7xl font-bold text-[#FF7777]">Flex Slider</h1>
      </header>
      <AuthForm />
    </div>
  );
};

export default Auth;
