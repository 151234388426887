import React from "react";
import { useUIContext } from "../../contexts/UIContext";
import { useWorkspaceContext } from "../../contexts/WorkspaceContext"; // Context
import Icon from "../common/Icon";
import { toast } from "react-toastify";
import { removeWorkspaceUser } from "../../api/workspace";

const WorkspaceMembersModal = () => {
  const { hideAllModals } = useUIContext(); // Modal kontrolü için context
  const { selectedWorkspace,setWorkspaceUsers, workspaceUsers, workspaceMyRole } =
    useWorkspaceContext(); // Context'ten üyeler ve kullanıcı rolü alınıyor

  // Üye çıkarma işlemi
  const handleRemoveMember = async (memberUsername: string) => {
    if (!selectedWorkspace) return;

    try {
      // Üyeyi veritabanından çıkar

      await removeWorkspaceUser(selectedWorkspace.workspaceId, memberUsername);
      setWorkspaceUsers((prevUsers) =>
        prevUsers.filter((user) => user.username !== memberUsername)
      );
      
      // Toast mesajı
      toast.info(`${memberUsername} çalışma alanından çıkarıldı.`);
    } catch (error: any) {
      console.error("Hata oluştu:", error.message);
      toast.error(
        "Üye çıkarılırken bir hata oluştu. Lütfen tekrar deneyin."
      );
    }
  };

  return (
    <div className="relative w-96 h-auto pt-8 bg-white rounded-xl">
      <div className="flex justify-between items-center pl-8 pr-4">
        <h2 className="text-gray-400">Çalışma Alanı Üyeleri</h2>
        <div onClick={hideAllModals}>
          <Icon
            name="close-icon"
            className="fill-gray-400 cursor-pointer"
            size={24}
          />
        </div>
      </div>
      <div className="w-full h-0.5 border border-gray-200 mt-4"></div>
      <div className="p-8">
        <h3 className="text-md mb-2 underline underline-offset-2 font-medium">
          Üyeler
        </h3>
        <div className="mb-8">
          {/* Üyeleri Listeleme */}
          {workspaceUsers.map((member, index) => (
            <div key={index} className="flex items-center justify-between mt-4">
              <p className="">{member.username}</p>
              <div className="relative flex items-center gap-2">
                <div
                  className={`absolute right-8 top-1/2 -translate-y-1/2 flex justify-center items-center py-1 px-2 rounded-xl ${
                    member.role === "owner"
                      ? "bg-orange-500" // Sahip için turuncu arka plan
                      : member.role === "admin"
                      ? "bg-red-500" // Admin için kırmızı arka plan
                      : "bg-green-500" // Üye için yeşil arka plan
                  }`}
                >
                  <p className="text-sm text-white capitalize">
                    {member.role === "owner"
                      ? "Sahip"
                      : member.role === "admin"
                      ? "Admin"
                      : "Üye"}
                  </p>
                </div>
                {/* Üye Çıkarma İkonu */}
                {((workspaceMyRole === "owner" &&
                  member.role !== "owner") || // Sahip ise, diğer herkese ikon
                  (workspaceMyRole === "admin" && member.role === "member")) && ( // Admin ise, sadece üyelere ikon
                  <div onClick={() => handleRemoveMember(member.username)}>
                    <Icon
                      name="remove-person-icon"
                      className="text-gray-400 cursor-pointer hover:text-gray-700"
                      size={20}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorkspaceMembersModal;
