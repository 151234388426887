import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getProjectById, updateProject } from "../api/project";
import SlidesBar from "../components/SlidesBar/SlidesBar";
import SlideSettingsBar from "../components/SlideSettingsBar/SlideSettingsBar";
import SlideCanvas from "../components/SlideCanvas/SlideCanvas";
import { useUIContext } from "../contexts/UIContext";
import { useWorkspaceContext } from "../contexts/WorkspaceContext";
import { useSlideContext } from "../contexts/SlideContext";
import Button from "../components/common/Button";
import UserDisplay from "../components/UserDisplay/UserDisplay";
import Icon from "../components/common/Icon";
import { toast } from "react-toastify";

const ProjectEdit = () => {
  const { projectUuid } = useParams();
  const {
    projectData,
    setProjectData,
    setProjectPermissions,
    setSlides,
    selectedSlide,
    setSelectedSlide,
    projectPermissions,
  } = useSlideContext();
  const { setSelectedWorkspace, ownedWorkspaces, memberWorkspaces } =
    useWorkspaceContext();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchProjectData = async () => {
      if (!projectUuid) return;

      try {
        const project = await getProjectById(projectUuid);

        const formattedProjectData = {
          projectUuid: project.uuid,
          title: project.title,
          type: project.type,
          isPublished: project.isPublished,
          workspaceId: project.workspace.id,
          workspaceName: project.workspace.name,
          updatedAt: project.updatedAt,
          data: { slides: project.data.slides },
        };

        setProjectData(formattedProjectData);

        if (project.permissions) {
          setProjectPermissions(project.permissions);
        }

        const foundWorkspace =
          ownedWorkspaces.find(
            (workspace) => workspace.workspaceId === project.workspace.id
          ) ||
          memberWorkspaces.find(
            (workspace) => workspace.workspaceId === project.workspace.id
          );

        if (foundWorkspace) {
          setSelectedWorkspace(foundWorkspace);
        }
      } catch (error) {
        console.error("Proje verisi alınamadı:", error);
        navigate("/not-found");
      }
    };

    fetchProjectData();
  }, [projectUuid]);

  useEffect(() => {
    if (projectData?.data?.slides) {
      setSlides(projectData.data.slides);
  
      // Eğer seçili slide yoksa veya mevcut slide artık slides içinde değilse
      if (
        !selectedSlide ||
        !projectData.data.slides.some((slide) => slide.id === selectedSlide.id)
      ) {
        setSelectedSlide(projectData.data.slides[0] || null); // İlk slide'a git
      }
    }
  }, [projectData]);


  

  // Autosave Mechanism
  useEffect(() => {
    if (!projectData || !projectUuid || !projectPermissions.includes("edit")) {
      
      return; // Kullanıcıda "edit" yetkisi yoksa kaydetme işlemini yapma
    }

    
    const timeoutId = setTimeout(async () => {
      try {
        setIsSaving(true);
        await updateProject(projectUuid, projectData);
        setIsSaving(false);
      } catch (error) {
        setIsSaving(false);
        console.error("Proje güncellenemedi:", error);
      }
    }, 2000);

    return () => clearTimeout(timeoutId); // Önceki zamanlayıcıyı temizle
  }, [projectData, projectUuid]);

  



  return (
    <div className="w-screen h-screen p-6 pb-14 bg-white">
      <Header />
      <div className="flex flex-col w-full h-full">
        <main className="flex justify-between gap-x-6 w-full h-full mt-8">
          <SlidesBar />
          <SlideCanvas />
          <SlideSettingsBar />
        </main>
      </div>
    </div>
  );
};

export default ProjectEdit;

const Header = () => {
  const { projectData, setProjectData, projectPermissions } = useSlideContext();
  const { showModal } = useUIContext();
  const { setSelectedWorkspace, ownedWorkspaces, memberWorkspaces } =
    useWorkspaceContext();
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    if (projectData) {
      const { workspaceId } = projectData;

      const foundWorkspace =
        ownedWorkspaces.find(
          (workspace) => workspace.workspaceId === workspaceId
        ) ||
        memberWorkspaces.find(
          (workspace) => workspace.workspaceId === workspaceId
        );

      if (foundWorkspace) {
        setSelectedWorkspace(foundWorkspace);
        navigate("/"); // Ana sayfaya yönlendir
      } else {
        navigate("/"); // Ana sayfaya yönlendir
      }
    } else {
      console.error("Project data mevcut değil!");
    }
  };

  const togglePublishStatus = () => {
    if (!projectData) return;

    // `isPublished` durumunu tersine çeviriyoruz
    const updatedProjectData = {
      ...projectData,
      isPublished: !projectData.isPublished,
    };

    // `projectData`'yı güncelliyoruz
    setProjectData(updatedProjectData);

    // Kullanıcıya bilgi mesajı gösteriyoruz
    if (updatedProjectData.isPublished) {
      toast.info("Proje başarıyla yayına alındı.");
      showModal("isPublishProjectModalVisible"); // Modal açılıyor
    } else {
      toast.info("Proje yayından kaldırıldı.");
    }

    // Backend'e güncellenen durumu gönder
    updateProject(projectData.projectUuid, updatedProjectData).catch((error) => {
      console.error("Projeyi yayınlama durumu güncellenemedi:", error);
      toast.error("Proje yayınlama durumu güncellenirken bir hata oluştu.");
    });
  };

  if (!projectData) {
    return null;
  }

  const canEdit = projectPermissions.includes("edit");

  return (
    <header className="flex justify-between">
      <div className="flex items-center gap-2">
        <Icon name="workspace-icon" size={20} />
        <h3 className="flex items-center gap-2 font-medium text-sm text-[#3d3d3c]">
          <span
            className="cursor-pointer hover:underline"
            onClick={handleNavigateHome}
          >
            {projectData.workspaceName || "Workspace"}
          </span>
          {">"}
          <span>{projectData.title || "Proje"}</span>
        </h3>
      </div>
      <div className="flex items-center justify-center gap-8">
        <Button
          label="Projeyi Paylaş"
          iconName="share-icon"
          className={`bg-[#0D92F4] ${
            canEdit ? "" : "cursor-not-allowed opacity-50"
          }`}
          textClassName="text-sm text-white"
          onClick={canEdit ? () => showModal("isShareProjectModalVisible") : undefined}
        />
        <Button
          label={
            projectData.isPublished ? "Yayından Kaldır" : "Projeyi Yayınla"
          }
          iconName={projectData.isPublished ? "unpublish-icon" : "publish-icon"}
          className={`bg-[#191919] ${
            canEdit ? "" : "cursor-not-allowed opacity-50"
          }`}
          textClassName="text-sm text-white"
          onClick={canEdit ? togglePublishStatus : undefined}
        />

        <UserDisplay />
      </div>
    </header>
  );
};
