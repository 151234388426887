import { useEffect, useState } from "react";
import { useWorkspaceContext } from "../../../contexts/WorkspaceContext";
import { UserItemProps } from "../../../types/ComponentsTypes";

type Permission = "view" | "edit" | "delete";


const UserItem: React.FC<UserItemProps> = ({ username, role,setMembersWithPermissions }) => {
  const { workspaceMyRole } = useWorkspaceContext();

  const roleTranslations: Record<string, string> = {
    owner: "Sahip",
    admin: "Admin",
    member: "Üye",
  };

  const permissions: Permission[] = ["view", "edit", "delete"];
  const [permissionArray, setPermissionArray] = useState<Permission[]>([]);

  // Role'e göre default yetkileri ayarla
  useEffect(() => {
    if (role === "owner") setPermissionArray([...permissions]); // Owner için tüm yetkiler

    if (role === "admin") setPermissionArray(["view", "edit"]); // Admin veya Member için sadece 'view'

    if (role === "member") setPermissionArray(["view"]);
  }, [role]);

  // Toggle permission
  const togglePermission = (permission: Permission) => {
    if (
      role === "owner" || // Owner yetkileri değiştirilemez
      (workspaceMyRole === "admin" && role === "admin") || // Admin diğer adminleri ve owner'ı değiştiremez
      (role === "admin" && permission === "view") // Admin'in view yetkisi değiştirilemez
    ) {
      return; // Hiçbir işlem yapmadan çık
    }

    setPermissionArray((prev) => {
      if (prev.includes(permission)) {
        // İzin zaten varsa, kaldır
        return prev.filter((p) => p !== permission);
      } else {
        // İzin yoksa ekle
        const updatedPermissions = [...prev, permission];
        // Eğer eklenen izin 'edit' veya 'delete' ise 'view' iznini ekle
        if (
          (permission === "edit" || permission === "delete") &&
          !updatedPermissions.includes("view")
        ) {
          updatedPermissions.push("view");
        }
        return updatedPermissions;
      }
    });
  };

  // Toggle all permissions
  const toggleAllPermissions = () => {
    if (
      role === "owner" || // Owner yetkileri değiştirilemez
      (workspaceMyRole === "admin" && role === "admin") // Admin diğer adminleri ve owner'ı değiştiremez
    ) {
      return; // Hiçbir işlem yapmadan çık
    }

    if (permissionArray.length === permissions.length) {
      // Sadece 'view' bırak
      setPermissionArray(role === "admin" ? ["view"] : []);
    } else {
      // Hepsini ekle
      setPermissionArray(["view", "edit", "delete"]);
    }
  };

  useEffect(() => {
    setMembersWithPermissions((prevMembers) => {
      // Önce mevcut kullanıcıyı bul
      const existingMemberIndex = prevMembers.findIndex(
        (member) => member.username === username
      );
  
      if (existingMemberIndex !== -1) {
        // Kullanıcı bulunduysa permissions'ı güncelle
        const updatedMembers = [...prevMembers];
        updatedMembers[existingMemberIndex] = {
          ...updatedMembers[existingMemberIndex],
          permissions: permissionArray, // Yeni permissionArray'i ata
        };
        return updatedMembers;
      } else {
        // Kullanıcı yoksa, yeni bir obje ekle
        return [
          ...prevMembers,
          {
            username,
            permissions: permissionArray,
          },
        ];
      }
    });
  }, [permissionArray, username, setMembersWithPermissions]);
  

  return (
    <div className="relative flex items-center mb-2">
      <div className="flex items-center gap-3">
        <p>{username}</p>
        <p
          className={`rounded-full py-0.5 px-2 text-white text-xs ${
            role === "owner"
              ? "bg-orange-500"
              : role === "admin"
              ? "bg-blue-500"
              : "bg-green-500"
          }`}
        >
          {roleTranslations[role] || "Bilinmeyen Rol"}
        </p>
      </div>

      <div className="absolute right-0 flex gap-16">
        {permissions.map((permission) => (
          <div
            key={permission}
            className={`w-8 h-1 bg-gray-200 rounded-full ${
              role === "owner" || // Owner yetkileri değiştiremez
              (workspaceMyRole === "admin" && role === "admin") || // Admin diğer adminleri ve owner'ı değiştiremez
              (role === "admin" && permission === "view") // Admin’in view yetkisi sabit
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }`}
            onClick={() => togglePermission(permission)}
          >
            <div className="w-full h-full relative">
              <div
                className={`absolute top-1/2 -translate-y-1/2 w-4 h-4 rounded-full ${
                  permissionArray.includes(permission)
                    ? "bg-blue-500 right-0"
                    : "bg-gray-500 left-0"
                }`}
              ></div>
            </div>
          </div>
        ))}
        <div
          className={`w-8 h-1 bg-gray-200 rounded-full ${
            role === "owner" ||
            (workspaceMyRole === "admin" && role === "admin")
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }`}
          onClick={toggleAllPermissions}
        >
          <div className="w-full h-full relative">
            <div
              className={`absolute top-1/2 -translate-y-1/2 w-4 h-4 rounded-full ${
                permissionArray.length === permissions.length
                  ? "bg-blue-500 right-0"
                  : "bg-gray-500 left-0"
              }`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
