import React, { useEffect, useState } from "react";
import { useWorkspaceContext } from "../../../contexts/WorkspaceContext";
import { useUIContext } from "../../../contexts/UIContext";
import { toast } from "react-toastify";
import Icon from "../../common/Icon";
import { createProject } from "../../../api/project";
import { Workspace, WorkspaceProject } from "../../../types/ContextTypes";
import UserItem from "./UserItem";
import { MemberWithPermissions } from "../../../types/ComponentsTypes";



const AddProjectModal = () => {
  const {
    ownedWorkspaces,
    memberWorkspaces,
    setOwnedWorkspaces,
    setMemberWorkspaces,
    workspaceUsers,
    workspaceMyRole,
    selectedWorkspace,
    setSelectedWorkspace,
  } = useWorkspaceContext();
  const { hideAllModals } = useUIContext();

  const [combinedWorkspaces, setCombinedWorkspaces] = useState<Workspace[]>([]);
  const [isWorkspaceDropdownOpen, setIsWorkspaceDropdownOpen] =
    useState<boolean>(false);
  const [projectName, setProjectName] = useState<string>("");
  const [projectMode, setProjectMode] = useState<
    "presentation" | "survey" | null
  >(null);
  const [membersWithPermissions,setMemberWithPermissions] = useState<MemberWithPermissions[]>([])

  // çalışma alanı kullanıcılarını owner,admin,member seklinde sıraya sokma
  const rolePriority = {
    owner: 1,
    admin: 2,
    member: 3,
  };

  const sortedWorkspaceUser = workspaceUsers.sort(
    (a, b) => rolePriority[a.role] - rolePriority[b.role]
  );

  useEffect(() => {
    // Projeleri birleştir
    const combinedWorkspaces = [...ownedWorkspaces, ...memberWorkspaces];
    setCombinedWorkspaces(combinedWorkspaces);
  }, [ownedWorkspaces, memberWorkspaces]);

  useEffect(() => {
    if (ownedWorkspaces.length > 0) {
      setSelectedWorkspace(ownedWorkspaces[0]);
    } else if (memberWorkspaces.length > 0) {
      setSelectedWorkspace(memberWorkspaces[0]);
    }
  }, []);

  const handleCreateProject = async () => {
    if (!selectedWorkspace) {
      toast.error("Çalışma alanı seçmelisiniz.");
      return;
    }
  
    if (!projectName.trim()) {
      toast.error("Proje adı boş olamaz.");
      return;
    }
  
    if (!projectMode) {
      toast.error("Proje türünü seçmelisiniz.");
      return;
    }
  
    try {
      const projectData = {
        title: projectName.trim(),
        type: projectMode,
        userPermissions: membersWithPermissions
      };
  
      const createdProject = await createProject(
        selectedWorkspace.workspaceId,
        projectData
      );
  
      toast.success("Proje başarıyla oluşturuldu!");

    // API'den dönen projeyi UI için dönüştür
    const newProject: WorkspaceProject = {
      uuid: createdProject.uuid,
      projectName: createdProject.projectName,
      type: createdProject.type,
      createdBy: createdProject.createdBy,
      isPublished: createdProject.isPublished,
      createdAt: new Date(createdProject.createdAt),
      updatedAt: new Date(createdProject.updatedAt),
    };

    // UI Güncellemesi: ownedWorkspaces ve memberWorkspaces içinde güncelle
    setOwnedWorkspaces((prev) =>
      prev.map((workspace) =>
        workspace.workspaceId === selectedWorkspace.workspaceId
          ? {
              ...workspace,
              projectCount: workspace.projectCount + 1,
              workspaceProjects: [...workspace.workspaceProjects, newProject],
            }
          : workspace
      )
    );

    setMemberWorkspaces((prev) =>
      prev.map((workspace) =>
        workspace.workspaceId === selectedWorkspace.workspaceId
          ? {
              ...workspace,
              projectCount: workspace.projectCount + 1,
              workspaceProjects: [...workspace.workspaceProjects, newProject],
            }
          : workspace
      )
    );

    hideAllModals(); // Modalı kapat
    } catch (error) {
      console.error("Proje oluşturulurken hata:", error);
      toast.error("Proje oluşturulurken bir hata oluştu.");
    }
  };
  


  return (
    <div className="relative w-[650px] h-auto bg-white rounded-xl pt-8">
      <div className="flex justify-between items-center pl-8 pr-4">
        <h2 className="text-gray-400">Yeni Proje</h2>
        <div onClick={hideAllModals}>
          <Icon
            name="close-icon"
            className="fill-gray-400 cursor-pointer"
            size={24}
          />
        </div>
      </div>
      <div className="w-full h-0.5 border border-gray-200 my-4"></div>
      <div className=" px-8 pb-6">
        <div className="flex justify-between items-center gap-12">
          <div className="flex-1">
            <h3 className="text-md mb-2 font-medium underline">
              Çalışma Alanı
            </h3>

            <div className="flex items-center justify-center">
              <div
                className="relative flex items-center justify-between py-3 px-3  w-full h-10 cursor-pointer border border-gray-200 shadow-lg rounded-lg"
                onClick={() => setIsWorkspaceDropdownOpen((prev) => !prev)}
              >
                <p className="text-sm">
                  {!selectedWorkspace
                    ? `${combinedWorkspaces[0]?.workspaceName.substring(0, 40)}`
                    : `${selectedWorkspace.workspaceName.substring(0, 40)}`}
                </p>
                <Icon name="arrow-down-icon" />
                {isWorkspaceDropdownOpen && (
                  <div className="absolute -bottom-4 left-1/2 -translate-x-1/2 translate-y-full flex flex-col w-full py-3 bg-white z-50 shadow-lg border border-gray-200 rounded-lg">
                    {combinedWorkspaces.map((workspace) => (
                      <p
                        className="text-sm px-3 py-2 hover:bg-gray-100"
                        key={workspace.workspaceId}
                        onClick={() => setSelectedWorkspace(workspace)}
                      >
                        {workspace.workspaceName}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex-1">
            <h3 className="text-md mb-2 font-medium underline">Proje</h3>
            <input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Proje Adı"
              className="w-full h-10 p-2 border border-gray-300 rounded-lg"
            />
          </div>
        </div>
        <div className="w-full h-0.5 border border-gray-200 my-4"></div>
        <h3 className="text-md font-medium underline mb-4">Proje Türü </h3>
        <div className="flex items-center justify-between gap-20">
          <div className="flex flex-col items-center justify-center flex-1  h-28 border border-gray-400 rounded-lg cursor-not-allowed">
            <Icon name="survey-mode-icon" size={32} className="text-gray-400" />
            <h3 className="text-gray-400 text-lg">Anket Modu</h3>
            <span className="font-medium text-lg">Yakında!</span>
          </div>
          <div
            className={`flex flex-col items-center justify-center flex-1 gap-2 h-28 border border-gray-400 rounded-lg cursor-pointer  ${
              projectMode === "presentation"
                ? "bg-gray-100 border-gray-600"
                : " hover:bg-gray-50"
            }`}
            onClick={() => setProjectMode("presentation")}
          >
            <Icon name="presentation-mode-icon" size={32} />
            <h3 className="text-lg">Sunum Modu</h3>
          </div>
        </div>

        <div className="w-full h-0.5 border border-gray-200 my-4"></div>
        {workspaceMyRole === "member" ? (
          <div>
            <h1 className="text-lg text-gray-500 font-medium text-center">
              Bu çalışma alanına proje ekleme yetkiniz yok!
            </h1>
          </div>
        ) : (
          <div className="relative">
            <div className="absolute right-0 flex gap-14">
              <p className="font-medium text-gray-500 text-xs underline">
                Görüntüleme
              </p>
              <p className="font-medium text-gray-500 text-xs underline">
                Düzenleme
              </p>
              <p className="font-medium text-gray-500 text-xs underline">
                Silme
              </p>
              <p className="font-medium text-gray-500 text-xs underline">
                Hepsi
              </p>
            </div>
            <h3 className="text-md mb-2 font-medium underline">Yetkiler </h3>
            <div className="flex flex-col">
              {sortedWorkspaceUser.map((user, index) => (
                <UserItem
                  key={user.username}
                  username={user.username}
                  role={user.role}
                  membersWithPermissions={membersWithPermissions}
                  setMembersWithPermissions={setMemberWithPermissions}
                />
              ))}
            </div>
          </div>
        )}

        <div className="flex justify-center mt-8">
          {workspaceMyRole !== "member" && (
            <button className="w-40 py-2 bg-black text-white rounded-lg" onClick={handleCreateProject}>
              Oluştur
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProjectModal;
