import React from "react";
import { useSlideContext } from "../../contexts/SlideContext";
import { useUIContext } from "../../contexts/UIContext";
import Icon from "../common/Icon";
import { toast } from "react-toastify";

const PublishProjectModal = () => {
  const { projectData } = useSlideContext();
  const { hideAllModals } = useUIContext();

  const handleCopyToClipboard = () => {
    if (projectData) {
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData.projectUuid}/publish`
      );
      toast.info("Link kopyalandı!");
    }
  };

  const handleNavigateToLink = () => {
    if (projectData) {
      window.open(
        `${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData.projectUuid}/publish`,
        "_blank"
      );
    }
  };

  return (
    <div className="relative w-96 bg-white rounded-xl p-6">
      {/* Başlık ve Kapatma İkonu */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium text-gray-800">Proje Yayınlandı</h2>
        <div onClick={hideAllModals}>
          <Icon
            name="close-icon"
            className="fill-gray-400 cursor-pointer"
            size={24}
          />
        </div>
      </div>

      <div className="mb-4">
        <p className="text-sm text-gray-600">
          Yayınlanan proje bağlantısı aşağıda verilmiştir. Bu bağlantıyı
          başkalarıyla paylaşabilirsiniz.
        </p>
        <div className="mt-3 flex items-center gap-3 p-2 border border-gray-300 rounded-lg">
          <span className="text-sm text-gray-700 flex-grow truncate">{`${process.env.REACT_APP_DOMAIN_URL}/projects/${projectData?.projectUuid}/publish`}</span>
          <div
            className="text-blue-500 hover:text-blue-700 text-sm cursor-pointer"
            onClick={handleCopyToClipboard}
          >
            <Icon name="copy-icon" size={20} />
          </div>
        </div>
      </div>

      {/* "Linke Git" Butonu */}
      <button
        className="w-full py-2 bg-[#0D92F4] text-white rounded-lg text-md font-medium hover:bg-[#0c82d2]"
        onClick={handleNavigateToLink}
      >
        Linke Git
      </button>
    </div>
  );
};

export default PublishProjectModal;
