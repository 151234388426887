import React from "react";
import Icon from "../common/Icon";
import { useUIContext } from "../../contexts/UIContext";
import templateTypes from "../../templateTypes.json";
import { useSlideContext } from "../../contexts/SlideContext";

const TemplateModal = () => {
  const { hideAllModals } = useUIContext();
  const { slides, setSlides, projectData, setProjectData } = useSlideContext();

  // const createNewSlide = (templateId: number) => {
  //   // Seçilen template
  //   const selectedTemplate = templateTypes.find(
  //     (template) => template.id === templateId
  //   );

  //   if (!selectedTemplate) {
  //     console.error("Geçersiz şablon seçimi.");
  //     return;
  //   }

  //   // En yüksek ID'yi bul
  //   const maxId =
  //     slides.length > 0 ? Math.max(...slides.map((slide) => slide.id)) : 0;

  //   // Yeni slide nesnesi
  //   const newSlide = {
  //     id: maxId + 1, // En yüksek ID'nin bir fazlası
  //     order: slides.length + 1, // Mevcut slayt sayısına göre yeni sıralama
  //     assetsLink: "",
  //     settings: {
  //       design: {
  //         titleSize: "medium",
  //         titleColor: "#333333",
  //         descSize: "small",
  //         descColor: "#666666",
  //       },
  //       layout: {
  //         mobile: "layout1",
  //         desktop: "layout1",
  //       },
  //     },
  //     template: {
  //       type: selectedTemplate.type,
  //       iconName: selectedTemplate.iconName,
  //       iconTitle: selectedTemplate.title,
  //       templateContent: {
  //         title: "",
  //         desc: "",
  //       },
  //     },
  //   };

  //   // Yeni slides dizisi
  //   const updatedSlides = [...slides, newSlide];

  //   // ProjectData güncelleme
  //   const updatedProjectData = {
  //     ...projectData,
  //     data: {
  //       ...projectData.data,
  //       slides: updatedSlides,
  //     },
  //   };

  //   // State'leri güncelle
  //   setSlides(updatedSlides);
  //   setProjectData(updatedProjectData);

  //   // Modal kapatma
  //   hideAllModals();
  // };

  return (
    <div className="relative w-72 h-auto py-6 bg-white rounded-xl">
      <div className="flex justify-between items-center pl-8 pr-4">
        <h2 className="text-gray-400">Şablonlar</h2>
        <div onClick={hideAllModals}>
          <Icon
            name="close-icon"
            className="fill-gray-400 cursor-pointer"
            size={24}
          />
        </div>
      </div>
      <div className="w-full h-0.5 border border-gray-200 mt-4"></div>
      <div>
        {templateTypes.map((templateType, index) => (
          <div
            key={index}
            className="flex items-center gap-2 px-8 py-3 cursor-pointer hover:bg-gray-200"
            // onClick={() => createNewSlide(templateType.id)}
          >
            <Icon name={templateType.iconName} size={20} />
            <p>{templateType.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplateModal;
