import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/projects`;

export const getProjectById = async (projectUuid: string): Promise<any> => {
  const token = localStorage.getItem("accessToken");

  if (!token) {
    throw new Error("No access token found. Please log in again.");
  }

  const response = await fetch(`${API_URL}/${projectUuid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Proje verisi alınamadı.");
  }

  return response.json(); // API'den gelen proje verisini döndür
};

export const getPublicProjectById = async (
  projectUuid: string
): Promise<any> => {
  const response = await fetch(`${API_URL}/${projectUuid}/public`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Proje yayında değil veya verisi alınamadı.");
  }

  return response.json(); // Public API'den gelen proje verisini döndür
};


export const createProject = async (
  workspaceId: number,
  projectData: {
    title: string;
    type: string;
    userPermissions: { username: string; permissions: string[] }[];
  }
) => {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    throw new Error("Access token bulunamadı.");
  }

  const response = await axios.post(`${API_URL}/${workspaceId}`, projectData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data; // Yeni proje döndürülüyor
};



  export const updateProject = async (
    projectUuid: string,
    updatedProjectData: any
  ): Promise<any> => {
    const token = localStorage.getItem("accessToken");
  
    if (!token) {
      throw new Error("No access token found. Please log in again.");
    }
  
    const response = await fetch(`${API_URL}/${projectUuid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedProjectData),
    });
  
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Failed to update project.");
    }
  
    return response.json(); // Güncellenmiş proje verisini döner
  };

  export const deleteProject = async (projectUuid: string): Promise<void> => {
    const token = localStorage.getItem("accessToken");
  
    if (!token) {
      throw new Error("No access token found. Please log in again.");
    }
  
    const response = await fetch(`${API_URL}/${projectUuid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (!response.ok) {
      throw new Error("Proje silinemedi.");
    }
  };

  export const refreshProjectUuid = async (projectUuid: string): Promise<{ newUuid: string }> => {
    const token = localStorage.getItem("accessToken");
  
    if (!token) {
      throw new Error("No access token found. Please log in again.");
    }
  
    const response = await fetch(`${API_URL}/${projectUuid}/refresh-uuid`, {
      method: "PATCH", // POST yerine PATCH kullandık
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Failed to refresh UUID.");
    }
  
    return response.json(); // Yeni UUID verisini döner
  };
  

  export const uploadSlideMedia = async (
    projectUuid: string,
    slideId: number,
    file: File
  ): Promise<string> => {
    const token = localStorage.getItem("accessToken");
  
    if (!token) {
      throw new Error("No access token found. Please log in again.");
    }
  
    const formData = new FormData();
    formData.append("file", file);
  
    const response = await fetch(
      `${API_URL}/${projectUuid}/slides/${slideId}/upload`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
  
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Failed to upload media.");
    }
  
    const data = await response.json();
    return data.url;
  };

  export const removeSlideMedia = async (
    projectUuid: string,
    slideId: number
  ): Promise<void> => {
    const token = localStorage.getItem("accessToken");
  
    if (!token) {
      throw new Error("No access token found. Please log in again.");
    }
  
    const response = await fetch(
      `${API_URL}/${projectUuid}/slides/${slideId}/remove`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Failed to remove media.");
    }
  };
  