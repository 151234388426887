import React from "react";
import { IconProps } from "../../types/ComponentsTypes";

const Icon: React.FC<IconProps> = ({ name, size = 24, className = "" }) => {
  return (
    <svg
      width={size}
      height={size}
      className={`fill-current ${className}`}
      aria-hidden="true"
    >
      <use href={`/assets/icons/sprite.svg#${name}`} />
    </svg>
  );
};

export default Icon;


