import React, { useState } from "react";
import { AuthInputProps } from "../../types/ComponentsTypes";
import Icon from "../common/Icon";
import { useAuthContext } from "../../contexts/AuthContext";

const AuthInput: React.FC<AuthInputProps> = ({
  iconName,
  type,
  placeholder,
  name,
}) => {
  const { authFormState, setAuthFormState } = useAuthContext();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  const handleFormInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempAuthForm = { ...authFormState };
    const { value } = event.target;

    // TypeScript'e bu key'in geçerli olduğunu açıkça belirtiyoruz
    tempAuthForm[authFormState.formType][
      name as keyof typeof authFormState["loginForm" | "registerForm"]
    ] = value;

    setAuthFormState(tempAuthForm);
  };

  return (
    <div className="relative flex items-center gap-4 w-full h-12 pl-4 bg-white rounded-lg">
      <Icon name={iconName} className="fill-gray-500" />
      <input
        name={name}
        value={
          authFormState[authFormState.formType][
            name as keyof typeof authFormState["loginForm" | "registerForm"]
          ]
        }
        type={type === "password" && isVisible ? "text" : type}
        placeholder={placeholder}
        className="border-none outline-none h-full"
        onChange={handleFormInput}
      />
      {type === "password" && (
        <div
          onClick={toggleVisibility}
          className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
        >
          {isVisible ? (
            <Icon name="visibility-on-icon" className="fill-gray-500" />
          ) : (
            <Icon name="visibility-off-icon" className="fill-gray-300" />
          )}
        </div>
      )}
    </div>
  );
};

export default AuthInput;
