import React, { useState } from "react";
import { useSlideContext } from "../../contexts/SlideContext";
import Icon from "../common/Icon";
import { SlideItemProps } from "../../types/ComponentsTypes";

const SlideItem: React.FC<SlideItemProps> = ({
  order,
  title,
  iconName,
  slideId,
}) => {
  const [isSlideItemHover, setIsSlideItemHover] = useState<boolean>(false);

  const { slides, setSlides, selectedSlide, setSelectedSlide,projectData,setProjectData } =
    useSlideContext();

  // Seçili slide kontrolü
  const isSelected = selectedSlide?.id === slideId;

  // Slide seçme fonksiyonu
  const handleSlideClick = () => {
    const newSelectedSlide = slides.find((slide) => slide.id === slideId) || null;
    setSelectedSlide(newSelectedSlide); // Seçilen slide'ın state'ini güncelle
  };

  // Slide silme fonksiyonu
  const handleDeleteSlide = (e: React.MouseEvent) => {
    e.stopPropagation(); // Tıklama olayının handleSlideClick'e gitmesini engeller
  
    const updatedSlides = slides.filter((slide) => slide.id !== slideId);
  
    const reorderedSlides = updatedSlides.map((slide, index) => ({
      ...slide,
      order: index + 1,
    }));
  
    const newSelectedSlide = isSelected
      ? reorderedSlides.length > 0
        ? reorderedSlides[0]
        : null
      : selectedSlide;
  
    const updatedProjectData = {
      ...projectData!,
      data: {
        ...projectData!.data,
        slides: reorderedSlides,
      },
    };
  
    setProjectData(updatedProjectData); // projectData güncelleniyor
    setSelectedSlide(newSelectedSlide); // seçili slide güncelleniyor
  };
  

  return (
    <div
      className={`flex items-center justify-between py-3 px-2 rounded-xl cursor-pointer 
        ${isSelected ? "bg-[#e9e9e8]" : ""}
        ${!isSelected && isSlideItemHover ? "bg-[#efefee]" : ""}`}
      onMouseEnter={() => setIsSlideItemHover(true)}
      onMouseLeave={() => setIsSlideItemHover(false)}
      onClick={handleSlideClick} // Tıklandığında seçme fonksiyonunu çağır
    >
      <div className="flex items-center gap-3">
        <div className="flex items-center justify-between w-16 h-8 px-2 bg-white rounded-lg shadow-md">
          <Icon name={iconName} size={18} className="fill-gray-500" />
          <span className="text-sm">{order}</span>
        </div>
        <span className="text-xs">{title}</span>
      </div>
      {isSlideItemHover && slides.length > 1 && (
        <div onClick={(e) => handleDeleteSlide(e)}>
          <Icon
            name="delete-icon"
            size={18}
            className="fill-gray-400 hover:fill-gray-700"
          />
        </div>
      )}
    </div>
  );
};

export default SlideItem;
