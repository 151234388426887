import { useState, useEffect, CSSProperties } from "react";
import { useSlideContext } from "../../contexts/SlideContext";

const SlideLayout = ({ readonly }: { readonly: boolean }) => {
  const { selectedSlide, setSlides, setProjectData, projectData, canvasState } =
    useSlideContext();

  const [tempTitle, setTempTitle] = useState("");
  const [tempDesc, setTempDesc] = useState("");

  // Seçilen slayt değiştiğinde geçici başlık ve açıklama değerlerini güncelle
  useEffect(() => {
    if (selectedSlide) {
      setTempTitle(selectedSlide.template.templateContent.title || "");
      setTempDesc(selectedSlide.template.templateContent.desc || "");
    }
  }, [selectedSlide]);

  // Eğer projectData veya selectedSlide mevcut değilse erken dönüş yap
  if (!selectedSlide || !projectData) {
    return <div>Slide seçilmedi veya proje verisi eksik!</div>;
  }

  const { settings } = selectedSlide;
  const { design, layout } = settings;
  const { screenType } = canvasState;

  // Başlık ve açıklama boyutları için eşlemeler
  const titleSizeMap = {
    large: "40px",
    medium: "24px",
    small: "16px",
  } as const;

  const descSizeMap = {
    large: "32px",
    medium: "18px",
    small: "12px",
  } as const;

  // Başlık stili
  const titleStyle: CSSProperties = {
    fontSize: titleSizeMap[design.titleSize as keyof typeof titleSizeMap],
    color: design.titleColor,
    fontWeight: "bold",
    textAlign: "center",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    width: "100%",
    padding: 0,
  };

  // Açıklama stili
  const descStyle: CSSProperties = {
    fontSize: descSizeMap[design.descSize as keyof typeof descSizeMap],
    color: design.descColor,
    textAlign: "center",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    width: "100%",
    resize: "none",
    padding: 0,
  };

  // ProjectData'yı güncelleyen yardımcı fonksiyon
  const updateProjectData = (key: "title" | "desc", value: string) => {
    const updatedSlides = projectData.data.slides.map((slide) =>
      slide.id === selectedSlide.id
        ? {
            ...slide,
            template: {
              ...slide.template,
              templateContent: {
                ...slide.template.templateContent,
                [key]: value,
              },
            },
          }
        : slide
    );

    const updatedProjectData = {
      ...projectData,
      data: {
        ...projectData.data,
        slides: updatedSlides,
      },
    };

    setSlides(updatedSlides); // Slaytları güncelle
    setProjectData(updatedProjectData); // Proje verisini güncelle
  };

  // Düzenleme tamamlandığında çağrılan fonksiyon
  const handleBlur = (key: "title" | "desc") => {
    if (
      (key === "title" &&
        tempTitle !== selectedSlide.template.templateContent.title) ||
      (key === "desc" &&
        tempDesc !== selectedSlide.template.templateContent.desc)
    ) {
      updateProjectData(key, key === "title" ? tempTitle : tempDesc);
    }
  };

  // Medya içeriğini render eden fonksiyon
  const renderMedia = () => {
    if (!selectedSlide.assetLink) return null;
    return selectedSlide.assetLink.endsWith(".mp4") ? (
      <video
        src={selectedSlide.assetLink}
        controls
        className="w-full h-auto rounded-md"
      />
    ) : (
      <img
        src={selectedSlide.assetLink}
        alt="Slide Media"
        className="w-full h-auto rounded-md"
      />
    );
  };

  if (selectedSlide.assetLink === "") {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full gap-4 p-4">
        <input
          type="text"
          value={tempTitle}
          placeholder="Başlık giriniz"
          style={titleStyle}
          onChange={(e) => setTempTitle(e.target.value)}
          onBlur={() => handleBlur("title")}
          className="text-center"
          readOnly={readonly}
        />
        <textarea
          value={tempDesc}
          placeholder="Açıklama giriniz"
          style={descStyle}
          onChange={(e) => setTempDesc(e.target.value)}
          onBlur={() => handleBlur("desc")}
          className="text-center"
          readOnly={readonly}
        />
      </div>
    );
  }

  if (screenType === "mobile") {
    switch (layout.mobile) {
      case "layout1":
        return (
          <div className="flex flex-col items-center justify-center w-full h-full gap-4 p-4">
            <input
              type="text"
              value={tempTitle}
              placeholder="Başlık giriniz"
              style={titleStyle}
              onChange={(e) => setTempTitle(e.target.value)}
              onBlur={() => handleBlur("title")}
              className="text-center"
              readOnly={readonly}
            />
            <textarea
              value={tempDesc}
              placeholder="Açıklama giriniz"
              style={descStyle}
              onChange={(e) => setTempDesc(e.target.value)}
              onBlur={() => handleBlur("desc")}
              className="text-center"
              readOnly={readonly}
            />

            {renderMedia()}
          </div>
        );
      case "layout2":
        return (
          <div className="flex flex-col items-center justify-center h-full gap-4 p-4">
            {renderMedia()}
            <input
              type="text"
              value={tempTitle}
              placeholder="Başlık giriniz"
              style={titleStyle}
              onChange={(e) => setTempTitle(e.target.value)}
              onBlur={() => handleBlur("title")}
              className="text-center"
              readOnly={readonly}
            />
            <textarea
              value={tempDesc}
              placeholder="Açıklama giriniz"
              style={descStyle}
              onChange={(e) => setTempDesc(e.target.value)}
              onBlur={() => handleBlur("desc")}
              className="text-center"
              readOnly={readonly}
            />
          </div>
        );
      case "layout3":
        return (
          <div className="relative flex flex-col justify-center h-full gap-4">
            <div className="absolute top-0 left-0">{renderMedia()}</div>
            <div className="flex flex-col items-center gap-2 p-4">
              <input
                type="text"
                value={tempTitle}
                placeholder="Başlık giriniz"
                style={titleStyle}
                onChange={(e) => setTempTitle(e.target.value)}
                onBlur={() => handleBlur("title")}
                className="text-center"
                readOnly={readonly}
              />
              <textarea
                value={tempDesc}
                placeholder="Açıklama giriniz"
                style={descStyle}
                onChange={(e) => setTempDesc(e.target.value)}
                onBlur={() => handleBlur("desc")}
                className="text-center"
                readOnly={readonly}
              />
            </div>
          </div>
        );
      case "layout4":
        return (
          <div
            className="relative flex items-center justify-center h-full w-full bg-cover bg-center"
            style={{
              backgroundImage: `url(${selectedSlide.assetLink || ""})`, // Arka plan resmi
            }}
          >
            {/* Karartma overlay */}
            <div className="absolute bg-black bg-opacity-50 w-full h-full"></div>

            {/* Başlık ve açıklama alanları */}
            <div className="relative text-center text-white">
              <input
                type="text"
                value={tempTitle}
                placeholder="Başlık giriniz"
                style={titleStyle} // Dinamik başlık stili
                onChange={(e) => setTempTitle(e.target.value)}
                onBlur={() => handleBlur("title")}
                className="text-xl font-bold bg-transparent border-none outline-none placeholder-gray-300 mb-4 w-full"
                readOnly={readonly}
              />
              <textarea
                value={tempDesc}
                placeholder="Açıklama giriniz"
                style={descStyle} // Dinamik açıklama stili
                onChange={(e) => setTempDesc(e.target.value)}
                onBlur={() => handleBlur("desc")}
                className="text-lg bg-transparent border-none outline-none placeholder-gray-300 resize-none w-full"
                readOnly={readonly}
              />
            </div>
          </div>
        );
      default:
        return <div>Geçersiz mobile layout</div>;
    }
  }

  if (screenType === "desktop") {
    switch (layout.desktop) {
      case "layout1":
        return (
          <div className="flex flex-col items-center justify-center h-full w-full gap-4 p-4">
            <div className="text-center">
              <input
                type="text"
                value={tempTitle}
                placeholder="Başlık giriniz"
                style={titleStyle}
                onChange={(e) => setTempTitle(e.target.value)}
                onBlur={() => handleBlur("title")}
                className="text-center"
                readOnly={readonly}
              />
              <textarea
                value={tempDesc}
                placeholder="Açıklama giriniz"
                style={descStyle}
                onChange={(e) => setTempDesc(e.target.value)}
                onBlur={() => handleBlur("desc")}
                className="text-center"
                readOnly={readonly}
              />
            </div>
            <div className="w-[660px]">{renderMedia()}</div>
          </div>
        );
      case "layout2":
        return (
          <div className="flex items-center justify-around h-full w-full gap-4 p-4">
            <div className="flex flex-col text-left w-1/2">
              <input
                type="text"
                value={tempTitle}
                placeholder="Başlık giriniz"
                style={titleStyle}
                onChange={(e) => setTempTitle(e.target.value)}
                onBlur={() => handleBlur("title")}
                readOnly={readonly}
              />
              <textarea
                value={tempDesc}
                placeholder="Açıklama giriniz"
                style={descStyle}
                onChange={(e) => setTempDesc(e.target.value)}
                onBlur={() => handleBlur("desc")}
                readOnly={readonly}
              />
            </div>
            <div className="w-2/5">{renderMedia()}</div>
          </div>
        );
      case "layout3":
        return (
          <div className="flex items-center justify-center h-full w-full gap-4 p-4">
            <div className="w-2/5">{renderMedia()}</div>
            <div className="flex flex-col text-left">
              <input
                type="text"
                value={tempTitle}
                placeholder="Başlık giriniz"
                style={titleStyle}
                onChange={(e) => setTempTitle(e.target.value)}
                onBlur={() => handleBlur("title")}
                readOnly={readonly}
              />
              <textarea
                value={tempDesc}
                placeholder="Açıklama giriniz"
                style={descStyle}
                onChange={(e) => setTempDesc(e.target.value)}
                onBlur={() => handleBlur("desc")}
                readOnly={readonly}
              />
            </div>
          </div>
        );
      case "layout4":
        return (
          <div className="flex flex-row-reverse h-full w-full gap-4 items-center">
            <div className="flex-1">{renderMedia()}</div>
            <div className="flex-1 flex flex-col justify-center h-full text-center">
              <input
                type="text"
                value={tempTitle}
                placeholder="Başlık giriniz"
                style={titleStyle}
                onChange={(e) => setTempTitle(e.target.value)}
                onBlur={() => handleBlur("title")}
                readOnly={readonly}
              />
              <textarea
                value={tempDesc}
                placeholder="Açıklama giriniz"
                style={descStyle}
                onChange={(e) => setTempDesc(e.target.value)}
                onBlur={() => handleBlur("desc")}
                readOnly={readonly}
              />
            </div>
          </div>
        );
      case "layout5":
        return (
          <div className="flex h-full w-full gap-4 justify-center items-center">
            <div className="flex-1">{renderMedia()}</div>
            <div className="flex-1 flex flex-col justify-center h-full text-center">
              <input
                type="text"
                value={tempTitle}
                placeholder="Başlık giriniz"
                style={titleStyle}
                onChange={(e) => setTempTitle(e.target.value)}
                onBlur={() => handleBlur("title")}
                readOnly={readonly}
              />
              <textarea
                value={tempDesc}
                placeholder="Açıklama giriniz"
                style={descStyle}
                onChange={(e) => setTempDesc(e.target.value)}
                onBlur={() => handleBlur("desc")}
                readOnly={readonly}
              />
            </div>
          </div>
        );
      case "layout6":
        return (
          <div
            className="relative flex items-center justify-center h-full w-full bg-cover bg-center"
            style={{
              backgroundImage: `url(${selectedSlide.assetLink || ""})`,
            }}
          >
            <div className="absolute bg-black bg-opacity-50 w-full h-full"></div>
            <div className="relative text-center text-white">
              <input
                type="text"
                value={tempTitle}
                placeholder="Başlık giriniz"
                style={titleStyle}
                onChange={(e) => setTempTitle(e.target.value)}
                onBlur={() => handleBlur("title")}
                readOnly={readonly}
              />
              <textarea
                value={tempDesc}
                placeholder="Açıklama giriniz"
                style={descStyle}
                onChange={(e) => setTempDesc(e.target.value)}
                onBlur={() => handleBlur("desc")}
                readOnly={readonly}
              />
            </div>
          </div>
        );
      default:
        return <div>Geçersiz desktop layout</div>;
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-full gap-4">
      Geçersiz ekran tipi
    </div>
  );
};

export default SlideLayout;
