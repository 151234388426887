import React, { useEffect, useRef, useState } from "react";
import Icon from "../common/Icon";
import { useSlideContext } from "../../contexts/SlideContext";
import TemplateDropdownMenu from "./TemplateDropdownMenu";
import LayoutDropdownMenu from "./LayoutDropdownMenu";
import { removeSlideMedia, uploadSlideMedia } from "../../api/project";

const SlideSettingsBar = () => {
  const {
    projectData,
    setProjectData,
    slides,
    setSlides,
    selectedSlide,
    setSelectedSlide,
    projectPermissions,
  } = useSlideContext();

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (
  //       dropdownRef.current &&
  //       !dropdownRef.current.contains(event.target as Node)
  //     ) {
  //       handleTemplateDropdownVisible(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const updateSlideSetting = (
    key: "titleSize" | "descSize" | "titleColor" | "descColor",
    value: string
  ) => {
    if (!selectedSlide || !projectData) return;

    const updatedSlides = slides.map((slide) =>
      slide.id === selectedSlide.id
        ? {
            ...slide,
            settings: {
              ...slide.settings,
              design: {
                ...slide.settings.design,
                [key]: value,
              },
            },
          }
        : slide
    );

    const updatedProjectData = {
      ...projectData,
      data: {
        ...projectData.data,
        slides: updatedSlides,
      },
    };

    setProjectData(updatedProjectData);
    setSelectedSlide(
      updatedSlides.find((slide) => slide.id === selectedSlide.id) || null
    );
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !selectedSlide || !projectData) return;

    setIsUploading(true);
    try {
      const uploadedURL = await uploadSlideMedia(
        projectData.projectUuid,
        selectedSlide.id,
        file
      );

      const updatedSlides = slides.map((slide) =>
        slide.id === selectedSlide.id
          ? { ...slide, assetLink: uploadedURL }
          : slide
      );

      const updatedProjectData = {
        ...projectData,
        data: {
          ...projectData.data,
          slides: updatedSlides,
        },
      };

      setProjectData(updatedProjectData);
      setSelectedSlide(
        updatedSlides.find((slide) => slide.id === selectedSlide.id) || null
      );
    } catch (error) {
      console.error("Dosya yüklenirken hata oluştu:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileRemove = async () => {
    if (!selectedSlide || !projectData) return;

    try {
      await removeSlideMedia(projectData.projectUuid, selectedSlide.id);

      const updatedSlides = slides.map((slide) =>
        slide.id === selectedSlide.id ? { ...slide, assetLink: "" } : slide
      );

      const updatedProjectData = {
        ...projectData,
        data: {
          ...projectData.data,
          slides: updatedSlides,
        },
      };

      setProjectData(updatedProjectData);
      setSelectedSlide(
        updatedSlides.find((slide) => slide.id === selectedSlide.id) || null
      );
    } catch (error) {
      console.error("Dosya kaldırılırken hata oluştu:", error);
    }
  };

  console.log(projectData);
  console.log(selectedSlide);

  if (!selectedSlide) {
    return <div>Seçilen slide yok!</div>;
  }

  return (
    <aside className="basis-[13%] h-full p-4 bg-[#f7f7f6] rounded-xl">
      {projectPermissions.includes("edit") && (
        <div>
          <div className="relative" ref={dropdownRef}>
            <div className="flex justify-between items-center w-full h-8 px-4 bg-white border border-gray-200 rounded-md cursor-pointer">
              <div className="flex items-center gap-2">
                <Icon size={20} name={selectedSlide.template.iconName} />
                <span>{selectedSlide.template.iconTitle}</span>
              </div>
            </div>
          </div>

          <SlideSettingsBarSep />

          {/* Resim veya Video Yükleme Alanı */}
          <div className="flex items-center justify-between mb-4">
            <span className="text-sm text-gray-500">Resim veya Video</span>
            <div className="flex items-center gap-2">
              {selectedSlide.assetLink ? (
                <div onClick={handleFileRemove} className="cursor-pointer">
                  <Icon name="delete-icon" size={20} className="fill-red-500" />
                </div>
              ) : (
                <div
                  onClick={() => fileInputRef.current?.click()}
                  className="cursor-pointer"
                >
                  <Icon name="add-icon" size={20} className="fill-green-500" />
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*,video/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
            </div>
          </div>

          {isUploading && (
            <p className="text-sm text-gray-500">Yükleniyor...</p>
          )}

          {selectedSlide.assetLink && (
            <div className="mt-4">
              {selectedSlide.assetLink.endsWith(".mp4") ? (
                <video
                  src={selectedSlide.assetLink}
                  controls
                  className="w-full"
                />
              ) : (
                <img
                  src={selectedSlide.assetLink}
                  alt="Uploaded"
                  className="w-full rounded-md"
                />
              )}
            </div>
          )}

          <SlideSettingsBarSep />

          <div className="flex flex-col">
            <h6 className="text-sm mb-6">Metin Ayarları</h6>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Başlık boyutu</span>
              <select
                value={selectedSlide.settings.design.titleSize}
                onChange={(e) =>
                  updateSlideSetting("titleSize", e.target.value)
                }
                className="border border-gray-300 rounded p-1"
              >
                <option value="large">Büyük</option>
                <option value="medium">Orta</option>
                <option value="small">Küçük</option>
              </select>
            </div>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Açıklama boyutu</span>
              <select
                value={selectedSlide.settings.design.descSize}
                onChange={(e) => updateSlideSetting("descSize", e.target.value)}
                className="border border-gray-300 rounded p-1"
              >
                <option value="large">Büyük</option>
                <option value="medium">Orta</option>
                <option value="small">Küçük</option>
              </select>
            </div>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Başlık Rengi</span>
              <input
                type="color"
                value={selectedSlide.settings.design.titleColor}
                onChange={(e) =>
                  updateSlideSetting("titleColor", e.target.value)
                }
                className="w-8 h-8 p-0 border-none cursor-pointer"
              />
            </div>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Açıklama Rengi</span>
              <input
                type="color"
                value={selectedSlide.settings.design.descColor}
                onChange={(e) =>
                  updateSlideSetting("descColor", e.target.value)
                }
                className="w-8 h-8 p-0 border-none cursor-pointer"
              />
            </div>
          </div>

          <SlideSettingsBarSep />

          <div className="flex flex-col">
            <h6 className="text-sm mb-6">Sayfa Düzeni</h6>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Mobil</span>
              <LayoutDropdownMenu type="mobile" />
            </div>
            <div className="flex items-center justify-between mb-3">
              <span className="text-sm text-gray-500">Masaüstü</span>
              <LayoutDropdownMenu type="desktop" />
            </div>
          </div>
        </div>
      )}
    </aside>
  );
};

const SlideSettingsBarSep = () => (
  <div className="w-full h-0.5 bg-gray-200 my-4"></div>
);

export default SlideSettingsBar;
